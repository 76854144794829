import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// PROJECT DETAIL COMPONENT - Logos Images w/ animation
const ProjectDetailLogos = (props: {
  logoA: any,             // ->  image data path of logo A  
  logoB: any,             // ->  image data path of logo B
}) => {

  // --- Getting image datas
  const logoA_img: IGatsbyImageData = props?.logoA && getImage(props.logoA.localFile);
  const logoB_img: IGatsbyImageData = props?.logoB && getImage(props.logoB.localFile);
  // ---

  // Hook w/ logos fade-up animation and scrollTriggers
  useEffect(() => {
    const fadeUp: gsap.core.Tween = gsap.to('.logo', {
      y: '-20%',
      opacity: 1,
      ease: 'power0.in',
      duration: 0.1,
    })

    ScrollTrigger.create({
      trigger: '#logo1',
      start: 'bottom bottom',
      end: 'start center',
      animation: fadeUp,
      toggleActions: 'play none none reverse',
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#logo2',
      start: 'top bottom',
      end: 'start center',
      animation: fadeUp,
      toggleActions: 'play none none reverse',
      scrub: 1
    })
  },[])
  // ---

  return (
    <> 
      <div className='flex flex-col justify-between w-full h-screen px-6 bg-black lg:pb-48 lg:px-24 xl:px-36'>
        { props?.logoA && props.logoA?.name.includes('freename') ?
          <>
            <div id='logo1' className='flex items-end justify-end w-full h-2/5'>
              <GatsbyImage image={logoA_img} alt={props?.logoA?.name} objectFit='cover' className='w-3/5 lg:w-1/4 h-3/5 lg:h-full logo lg:mr-[20%] opacity-0 translate-y-[50%]'/>
            </div>
            <div id='logo2' className='flex items-center justify-start w-full h-4/5'>
              <GatsbyImage image={logoB_img} alt={props?.logoA?.name} objectFit='cover' className='w-2/3 lg:w-1/3 h-1/2 lg:h-full logo lg:ml-[15%] opacity-0 translate-y-[50%]'/>
            </div>
          </>
          :
          <div id='logo1'>
            <GatsbyImage image={logoA_img} alt={props?.logoA?.name}/>
          </div>
        }
      </div>
    </>
  )
}

export default ProjectDetailLogos;